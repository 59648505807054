import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { Input } from 'components/primitives/form';
import { Input as Primitive } from 'components/primitives/form';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

//214457_Account_Creation
const componentBuilder = ({ field, form, ...rest }) => {
  const [displayValue, setDisplayValue] = useState(field.value || '');
  const postalCode = useSelector(state => state.user.cityPostal);

  useEffect(() => {
    if (postalCode !== +displayValue)
      setDisplayValue(postalCode);
    setValue(postalCode);
  }, [postalCode]);

  const setValue = newValue => {
    setDisplayValue(newValue);
    form.setFieldValue(field.name, newValue);
  };

  return (
    <Primitive
      {...field}
      {...rest}
      value={displayValue}
    />
  );
};

const ZipCodeField = props => {
  return (
    <Field
      type="text"
      component={componentBuilder}
      as={Input}
      {...props}
    />
  );
};

ZipCodeField.propTypes = fieldPropTypes;

export default ZipCodeField;
