import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { Input } from 'components/primitives/form';
import { Input as Primitive } from 'components/primitives/form';
import { useState, useEffect } from 'react';

//TICKET 139013 - 3.7. Customer registration – RNC/Cedula number
const displayValueRegex = /^-?\d*$/;
const componentBuilder = ({ field, form, ...rest }) => {
  const [displayValue, setDisplayValue] = useState(field.value || '');

  useEffect(() => {
    if (field.value != null && field.value !== +displayValue)
      setDisplayValue(field.value);
  }, [field.value]);

  const setValue = newValue => {
    setDisplayValue(newValue);

    //TICKET 171373 - ERROR EN LA CREACION DE CLIENTES 
    form.setFieldValue(field.name, newValue);
  };

  const onChange = e => {
    const newValue = e.target.value;

    if (!displayValueRegex.test(newValue))
      return;

    setValue(newValue);
  };

  const onPaste = e => {
    e.preventDefault();

    const newValue = e.clipboardData.getData('text')
      .replace(/-/g, (match, offset) => offset ? '' : match) // remove all non-first '-'
      .replace(/[^-\d]/g, '');

    setValue(newValue);
  };

  return (
    <Primitive
      {...field}
      {...rest}
      value={displayValue}
      onChange={onChange}
      onPaste={onPaste}
    />
  );
};

const RncCedulaNumberField = props => {
  return (
    <Field
      type="text"
      component={componentBuilder}
      as={Input}
      {...props}
    />
  );
};

RncCedulaNumberField.propTypes = fieldPropTypes;

export default RncCedulaNumberField;
