import PropTypes from 'prop-types';
import ErrorMessage from '../ErrorMessage';
import { getIn, useFormikContext } from 'formik';
import FormGroupTemplate from './FormGroupTemplate';
import { createLabelId, createErrorMsgId } from '../helpers';
import FieldLabel from '../FieldLabel';
import { useSelector } from 'react-redux';

const FormGroup = ({
  fieldName,
  fieldTitle,
  fieldComponent: FieldComponent,
  fieldDescriptionComponent: DescriptionComponent,
  className,
  ...fieldProps
}) => {
  const formik = useFormikContext();

  let fieldError = getIn(formik.errors, fieldName);
  if (fieldError && getIn(formik.touched, fieldName))
    className = className ? `${className} row-error` : 'row-error';
  else
    fieldError = null;

  const formName = formik.status.formName;
  const labelId = createLabelId(formName, fieldName);
  const errorId = createErrorMsgId(formName, fieldName);
  if (FieldComponent.notNative)
    fieldProps.labelId = labelId;

  //TICKET 139013 - 3.7. Customer registration � RNC/Cedula number
  if (typeof document !== 'undefined') {
    if (fieldName === 'Address.RncCedulaNumber') {
      const { validation } = fieldProps;
      const minMax = useSelector(state => state.user.minMaxValue);
      if (minMax && minMax.split('-').length === 2) {
        const max = parseInt(minMax.split('-')[0]);
        const min = parseInt(minMax.split('-')[1]);
        fieldProps = { ...fieldProps, maxLength: max, validation: { ...validation, minLength: { min, max } } }
      }
      else
        fieldProps = { ...fieldProps, maxLength: 11, validation: { ...validation, minLength: { min: 9, max: 11 } } }

    }
  }
  return (
    <FormGroupTemplate
      className={className}
      label={<FieldLabel fieldName={fieldName} required={fieldProps.required} notNative={FieldComponent.notNative}>{fieldTitle}</FieldLabel>}
      field={<FieldComponent fieldName={fieldName} fieldTitle={fieldTitle} {...fieldProps} />}
      error={<ErrorMessage fieldName={fieldName} id={errorId}>{fieldError}</ErrorMessage>}
      description={DescriptionComponent && <DescriptionComponent />}
      aria-labelledby={labelId}
    />
  );
};

FormGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldTitle: PropTypes.string,
  fieldComponent: PropTypes.elementType.isRequired,
  fieldDescriptionComponent: PropTypes.elementType,
  className: PropTypes.string,
};

export default FormGroup;