import styles from 'components/primitives/form/Select.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'components/primitives/form';
import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { getIn, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { updateCityPostal } from 'behavior/user';

//214457_Account_Creation
const emptyOption = { name: '', value: '', postal: '' };

const CityDropdownList = ({
  fieldName,
  fieldTitle,
  validation,
  items,
  placeholderTextKey,
  required,
  ...additionalProps
}) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();
  const currentValue = getIn(values, fieldName);
  const [cityPostal, setCityPostal] = useState();
  const dispatch = useDispatch();

  //this is needed to correlate initial value with available options
  useEffect(() => {
    if (!items.length || currentValue === undefined)
      return;

    let properValue = currentValue;
    if (currentValue === '' || currentValue === null)
      properValue = placeholderTextKey || !required ? '' : items[0].value;
    else if (!items.some(i => i.value === currentValue))
      properValue = '';

    if (properValue !== currentValue) {
      setFieldValue(fieldName, properValue, false);
      setFieldTouched(fieldName, false);
    }

    var selectedCityPostalVal = items.filter(c => c.name === currentValue)[0]?.postal;

    if (selectedCityPostalVal) {     
      setCityPostal(selectedCityPostalVal);
    }

  }, [items, currentValue]);

  useEffect(() => {
    dispatch(updateCityPostal(cityPostal));
  }, [cityPostal]);

  if (!required)
    items = [emptyOption, ...items];

  const isInvalid = !!(getIn(errors, fieldName) && getIn(touched, fieldName));

  debugger


  return (
    <>
      <Field
        items={items}
        fieldName={fieldName}
        fieldTitle={fieldTitle}
        validation={validation}
        as={Select}
        onChange={value => setFieldValue(fieldName, value)}
        className={styles.wide}
        placeholderTextKey={placeholderTextKey}
        required={required}
        isInvalid={isInvalid}
        {...additionalProps}
      />

      <input type="hidden" id="citySelectField" value={cityPostal} />
    </>
  );
};

CityDropdownList.propTypes = {
  ...fieldPropTypes,
  items: PropTypes.array,
  placeholderTextKey: PropTypes.string,
  required: PropTypes.bool,
};

CityDropdownList.notNative = true;

export default CityDropdownList;
