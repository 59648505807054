import PropTypes from 'prop-types';
import { ReadOnlyFormGroup } from '../../formGroups';

const CityReadOnlyFormGroup = ({ displayValue, initialValue, fieldName, items, ...props }) => {
  const cityId = initialValue || displayValue,
    city = items && items.find(city => city.value === cityId);

  return (
    <ReadOnlyFormGroup
      fieldName={fieldName}
      displayValue={city && city.name || city}
      {...props}
    />
  );
};

CityReadOnlyFormGroup.propTypes = {
  displayValue: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
};

export default CityReadOnlyFormGroup;
