import {
  HtmlSafeTextBoxField, EmailAddressField, CheckboxField, PhoneNumberField, TextAreaField, DecimalField,
  DropdownField, IntegerField, BooleanDropdownField, DatePickerField, DateDropdownField,
} from '../fields';
import { BooleanRadioFormGroup, ReadOnlyFormGroup } from '../formGroups';
import CountryDependentTextBoxField from './fields/CountryDependentTextBoxField';
import {
  StateFormGroup, StateReadOnlyFormGroup,
  CountryReadOnlyFormGroup,
  DateReadOnlyFormGroup,
  StreetNoFormGroup, StreetNoReadOnlyFormGroup,
  CityReadOnlyFormGroup,
} from './formGroups';

//TICKET 139013 - 3.7. Customer registration � RNC/Cedula number
export const fieldsMapping = {
  'STRING': HtmlSafeTextBoxField,
  'STRING_DROPDOWN': DropdownField,
  'INTEGER': IntegerField,
  'INTEGER_DROPDOWN': DropdownField,
  'BIG_INTEGER': IntegerField,
  'BIG_INTEGER_DROPDOWN': DropdownField,
  'DECIMAL': DecimalField,
  'DECIMAL_DROPDOWN': DropdownField,
  'BOOLEAN_CHECK_BOX': CheckboxField,
  'BOOLEAN_DROPDOWN': BooleanDropdownField,
  'MULTILINE_TEXT': TextAreaField,
  'EMAIL': EmailAddressField,
  'PHONE_NUMBER': PhoneNumberField,
  'ADDRESS_NAME': HtmlSafeTextBoxField,
  'COUNTRY': DropdownField,
  'VatRegistrationType': DropdownField,
  'ZIP_CODE': CountryDependentTextBoxField,
  'TAX_CODE': CountryDependentTextBoxField,
  'ENUM_DROPDOWN': DropdownField,
  'SHOP_ACCOUNT_EMAIL': EmailAddressField,
  'DATE_DATE_PICKER': DatePickerField,
  'DATE_DROPDOWNS': DateDropdownField,
  'City': DropdownField,
};

const readOnlyEditors = [
  'ADDRESS_NAME_READ_ONLY',
  'STRING_READ_ONLY',
  'BOOLEAN_READ_ONLY',
  'EMAIL_READ_ONLY',
  'SHOP_ACCOUNT_EMAIL_READ_ONLY',
  'ZIP_CODE_READ_ONLY',
  'INTEGER_READ_ONLY',
  'ENUM_READ_ONLY',
  'BIG_INTEGER_READ_ONLY',
  'DECIMAL_READ_ONLY',
  'TAX_CODE_READ_ONLY',
  'PHONE_NUMBER_READ_ONLY',
  'SHOP_ACCOUNT_EMAIL_READ_ONLY',
];

export const formGroupsMapping = {
  'STATE': StateFormGroup,
  'STATE_READ_ONLY': StateReadOnlyFormGroup,
  'COUNTRY_READ_ONLY': CountryReadOnlyFormGroup,
  'CITY_READ_ONLY': CityReadOnlyFormGroup,
  'DATE_READ_ONLY': DateReadOnlyFormGroup,
  'BOOLEAN_RADIO': BooleanRadioFormGroup,
  'STREET_NO': StreetNoFormGroup,
  'STREET_NO_READ_ONLY': StreetNoReadOnlyFormGroup,
  ...readOnlyEditors.reduce((formGroups, editorName) => {
    formGroups[editorName] = ReadOnlyFormGroup;
    return formGroups;
  }, {}),
};
